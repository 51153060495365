import * as React from "react"
import Layout from "../components/layout";
import * as indexStyles from "./index.module.css";
import bcImage from "../images/b-and-c.png";
import dateImage from "../images/date.png";
import illusImage from "../images/illus.png";

const IndexPage = () => {
  return (
    <Layout>
      <div className={indexStyles.container}>
          <img alt="Bebe and Charlie, Save the Date, 25th May 2024"
              className={indexStyles.bcImage} src={bcImage} />
          <img alt="Bebe and Charlie, Save the Date, 25th May 2024"
              className={indexStyles.illusImage} src={illusImage} />
          <img alt="Bebe and Charlie, Save the Date, 25th May 2024"
              className={indexStyles.dateImage} src={dateImage} />
      </div>
      <div className={indexStyles.credit}>
          Illustrations by{" "}
          <a target="_blank" rel="noreferrer"
            href="https://www.instagram.com/andyxmirandaa/">
              Andrea Miranda
          </a>
      </div>
    </Layout>
  )
}

export default IndexPage;

export const Head = () => <title>Bebe and Charlie</title>;
